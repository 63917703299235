import React from "react";
import Hexagon from "./Common/Hexagon";

const HexagonalLogo = () => {
  return (
    <div className="md:pt-5">
      <div className="items-center flex flex-col">
        <div className="flex">
          <div className="relative" style={{ marginRight: "-25%" }}>
            <Hexagon xPercent={"50%"} hexagonPath={"hexagonPath1"} text="Technology" textColor="rgba(255, 209, 26, 1)"/>
          </div>
          <div className="relative">
            <Hexagon xPercent={"58%"} hexagonPath={"hexagonPath2"} text="Business" textColor="rgba(255, 209, 26, 1)"/>
          </div>
        </div>
        <div className="relative" style={{ marginTop: "-25%", marginLeft: "-25%" }}>
          <Hexagon fontSize="28" fontWeight={600} hexagonPath={"hexagonPath3"} text="TechB" text2="softwares" color="rgba(230, 184, 0, 0.8)" textColor="black" background= "rgba(230, 184, 0, 0.5)"/>
        </div>
      </div>
    </div>
  );
};

export default HexagonalLogo;
