import React from "react";

const Main = () => {
  return (
    <div className="w-full text-white font-semibold lg:ml-28 flex flex-col gap-10 lg:gap-20 text-center sm:text-left">
      <div className="text-5xl lg:text-8xl desktop:text-9xl flex flex-col gap-4 items-left animate__animated animate__zoomIn">
        <span>Empowering </span>
        <span className="text-highlight">
          Business <span className="text-white">with </span>
        </span>
        <span className="text-highlight">
          Technology
        </span>
      </div>
      <div className="text-xl lg:text-3xl desktop:text-4xl animate__animated animate__zoomIn ">
        <span>Delivering software services, with an aim to be <span className="text-highlight">TECHNOLOGY PARTNER</span> for <span className="text-highlight">BUSINESS</span>.
        </span>
      </div>
    </div>
  );
};

export default Main;
