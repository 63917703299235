import React from "react";
// import AboutTechB from "./Home/AboutTechB";
// import ServicesHome from "./Home/ServicesHome";
import TechPartner from "./Home/TechPartner";
import EsteemedClients from "./Home/EsteemedClients";
import HomeMain from "./Home/HomeMain";
import ContactHome from "./Home/ContactHome";
import ProductOffrings from "./Home/ProductOffrings";
import TechTools from "./Technologies/TechTools";

const Home = () => {
  return (
    <div className="w-full flex flex-col gap-14 md:gap-7">
      <HomeMain />
      {/* <AboutTechB /> */}
      {/* <ServicesHome /> */}
      <ProductOffrings/>
      <TechPartner />
      <TechTools/>
      <EsteemedClients />
      <ContactHome/>
    </div>
  );
};

export default Home;
