import React from "react";
import Form from "../../Components/Common/Form";
import { MdOutlineMailOutline, MdOutlineHorizontalRule } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { SlGlobe } from "react-icons/sl";
import { FaFacebookSquare } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const ContactUs = () => {
  return (
    <div className="flex flex-col sm:flex-row w-full gap-6 desktop:gap-10 text-white p-5 lg:pl-20">
      <div className="sm:w-2/5 flex flex-col lg:gap-2 text-left">
        <p className="text-highlight text-2xl lg:text-5xl desktop:text-7xl">Career</p>
        <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
        <div className="flex flex-col gap-1 lg:gap-5 desktop:gap-6 text-base lg:text-2xl desktop:text-3xl">
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Software Engineer</div>
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Graphics Designer</div>
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Digital Marketing</div>
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Software Architect</div>
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Project Manager</div>
          <div className="flex items-center"><span className="pr-1 text-highlight"><GoDotFill /></span>Product Manager</div>
        </div>
      </div>
      <div className="sm:w-2/5 flex flex-col lg:gap-2 text-sm lg:text-2xl desktop:text-3xl text-left">
        <p className="text-highlight text-2xl lg:text-5xl desktop:text-7xl">Address</p>
        <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
        <div className="flex flex-col gap-5 lg:gap-7 desktop:gap-8">
          <div className="flex flex-col gap-1 lg:gap-2 desktop:gap-4">
            <div>
              Location:</div>
            <p>508, Parakh Capital,</p>
            <p>Hadapsar, Pune </p>
            <p>Maharashtra</p>
            <p> 411028</p>
          </div>
          <div className="flex flex-col gap-2 lg:gap-3 desktop:gap-5">
            <p>Contact:</p>
            <p className="flex gap-2 items-center"><span className="text-highlight"><IoCallOutline /></span>+91 9529556802</p>
            <p className="flex gap-2 items-center"><span className="text-highlight"><MdOutlineMailOutline /></span>info@techbsoftwares.com</p>
            <p className="flex gap-2 items-center"><span className="text-highlight"><SlGlobe /></span>www.techbsoftwares.com</p>
            <div className="flex gap-3 lg:gap-5 desktop:gap-7 items-center text-highlight">
              <a href="https://www.linkedin.com/company/techbsoftwares/"><FaLinkedin /></a>
              <span><GrInstagram /></span>
              <span><FaFacebookSquare /></span>
              <span><FaXTwitter /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-2/5 flex flex-col lg:gap-2">
        <p className="text-highlight text-2xl lg:text-5xl desktop:text-7xl">Contact Us</p>
        <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
        <div>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
