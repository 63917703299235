import React from "react";

const FeaturesCard = ({ imgPath, imgName }) => {
  return (
    <div className="h-[12vh] sm:h-[20vh] md:h-[10vh] w-[28vh] sm:w-[18vh] md:w-[12vh] flex lg:flex-col gap-5 sm:gap-2 text-left justify-center sm:text-center items-center p-1">
      <img src={imgPath} height={"50%"} width={"50%"} alt={imgName} className="h-[10vh] w-[10vh]" />
      <p className="text-lg lg:text-xl desktop:text-2xl">{imgName}</p>
    </div>
  );
};

export default FeaturesCard;
