import React from "react";
import Card from "../../Components/Common/Card";

const TechPartner = () => {
  return (
    <div className="flex flex-col gap-10 md:gap-14 lg:gap-20 desktop:gap-28 w-full md:my-10 lg:my-20">
      <div className="flex flex-col sm:flex-row sm:gap-3 justify-center text-center text-3xl lg:text-7xl desktop:text-8xl">
        <h1 className="text-white">TechB </h1>
        <span className="text-highlight">Your Technology Partner</span>
      </div>
      <div className="flex flex-col items-center justify-between">
        <div className="flex sm:flex-col lg:flex-row gap-5 desktop:gap-16">
          <div className="flex flex-col sm:flex-row gap-5 desktop:gap-16">
            <Card text1="Cloud" text2="Softwares" />
            <Card text1="Technology" text2="Partner" />
            <Card text1="Quality" text2="Deliverables" />
          </div>
          <div className="flex flex-col sm:flex-row gap-5 desktop:gap-16">
            <Card text1="Agile" text2="Process" />
            <Card text1="Timely" text2="Release" />
            <Card text1="Tech" text2="Support" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechPartner;
