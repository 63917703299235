import React, { useRef, useEffect } from "react";
import AwsLogo from "../../assets/Other/AwsLogo.jpg";
import angularLogo from "../../assets/Other/angularLogo.jpg";
import BootstrapLogo from "../../assets/Other/BootstrapLogo.jpg";
import CssLogo from "../../assets/Other/CssLogo.jpg";
import CloudflareLogo from "../../assets/Other/CloudflareLogo.jpg";
import DockerLogo from "../../assets/Other/DockerLogo.jpg";
import DjangoLogo from "../../assets/Other/DjangoLogo.jpg";
import elementorLogo from "../../assets/Other/elementorLogo.jpg";
import firebaseLogo from "../../assets/Other/firebaseLogo.jpg";
import GoogleAnalyticsLogo from "../../assets/Other/GoogleAnalyticsLogo.jpg";
import HtmlLogo from "../../assets/Other/HtmlLogo.jpg";
import JavascriptLogo from "../../assets/Other/JavascriptLogo.jpg";
import MongoDBLogo from "../../assets/Other/MongoDBLogo.jpg";
import mysqlLogo from "../../assets/Other/mysqlLogo.jpg";
import nodeJsLogo from "../../assets/Other/nodeJsLogo.jpg";
import PostgressLogo from "../../assets/Other/PostgressLogo.jpg";
import pythonLogo from "../../assets/Other/pythonLogo.jpg";
import reactLogo from "../../assets/Other/reactLogo.jpg";
import SassLogo from "../../assets/Other/SassLogo.jpg";
import SqliteLogo from "../../assets/Other/SqliteLogo.jpg";
import WixLogo from "../../assets/Other/WixLogo.jpg";
import wordpressLogo from "../../assets/Other/wordpressLogo.jpg";
import kubernetsLogo from "../../assets/Other/kubernetsLogo.jpg";
import GoogleMLKitLogo from "../../assets/Other/GoogleMLKitLogo.jpg";
import ImgCard from "../../Components/Common/ImgCard";

const TechTools = () => {
  const carouselRef = useRef(null);
  const carouselRef2 = useRef(null);
  const carouselRef3 = useRef(null);

  useEffect(() => {
    const isMediumScreenOrAbove = window.matchMedia("(min-width: 760px)").matches;
    if (isMediumScreenOrAbove) {
      const intervalId = setInterval(() => {
        handleScroll();
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const handleScroll = () => {
    const firstSlide = carouselRef.current.children[0];
    const firstSlide2 = carouselRef2.current.children[0];
    const firstSlide3 = carouselRef3.current.children[0];
    carouselRef.current.style.transition = "transform 0.5s ease-in-out";
    carouselRef2.current.style.transition = "transform 0.5s ease-in-out";
    carouselRef3.current.style.transition = "transform 0.5s ease-in-out";
    carouselRef.current.style.transform = "translateX(-33.33%)";
    carouselRef2.current.style.transform = "translateX(-33.33%)";
    carouselRef3.current.style.transform = "translateX(-33.33%)";
    setTimeout(() => {
      carouselRef.current.appendChild(firstSlide);
      carouselRef2.current.appendChild(firstSlide2);
      carouselRef3.current.appendChild(firstSlide3);
      carouselRef.current.style.transition = "none";
      carouselRef2.current.style.transition = "none";
      carouselRef3.current.style.transition = "none";
      carouselRef.current.style.transform = "translateX(0)";
      carouselRef2.current.style.transform = "translateX(0)";
      carouselRef3.current.style.transform = "translateX(0)";
    }, 500);
  };

  return (
    <div className="w-full text-white flex flex-col gap-7 md:gap-10 lg:gap-20 desktop:gap-28 px-10 p-10 md:p-5 lg:p-0 lg:mb-10">
      <div className="text-center flex flex-col gap-3">
        <h1 className="text-4xl lg:text-7xl desktop:text-8xl text-highlight">Technology</h1>
        <h1 className="text-lg lg:text-3xl desktop:text-4xl">
          THE RIGHT TECH IS CHOSEN FOR THE RIGHT PROBLEM.
        </h1>
      </div>
      <div className="flex flex-col gap-4 md:gap-6 lg:gap-10">
        <div className="carousel-container overflow-hidden md:flex md:justify-center">
          <div className="md:w-[56vh] lg:w-[170vh] desktop:w-[160vh]" style={{ overflowX: "hidden" }}>
            <div className="flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-14 justify-center items-center slide" ref={carouselRef}>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"React"} imgPath={reactLogo} />
                <ImgCard imgName={"Angular"} imgPath={angularLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Javascript"} imgPath={JavascriptLogo} />
                <ImgCard imgName={"CSS"} imgPath={CssLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"HTML"} imgPath={HtmlLogo} />
                <ImgCard imgName={"Bootstrap"} imgPath={BootstrapLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Python"} imgPath={pythonLogo} />
                <ImgCard imgName={"Django"} imgPath={DjangoLogo} />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-container overflow-hidden md:flex md:justify-center">
          <div className="md:w-[56vh] lg:w-[170vh] desktop:w-[160vh]" style={{ overflowX: "hidden" }}>
            <div className="flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-14 justify-center items-center slide" ref={carouselRef2}>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Node JS"} imgPath={nodeJsLogo} />
                <ImgCard imgName={"MongoDB"} imgPath={MongoDBLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"MySQL"} imgPath={mysqlLogo} />
                <ImgCard imgName={"Firebase"} imgPath={firebaseLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Sqlite"} imgPath={SqliteLogo} />
                <ImgCard imgName={"SASS"} imgPath={SassLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Wix"} imgPath={WixLogo} />
                <ImgCard imgName={"AWS"} imgPath={AwsLogo} />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-container overflow-hidden md:flex md:justify-center">
          <div className="md:w-[56vh] lg:w-[170vh] desktop:w-[160vh]" style={{ overflowX: "hidden" }}>
            <div className="flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-14 justify-center items-center slide" ref={carouselRef3}>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Elementor"} imgPath={elementorLogo} />
                <ImgCard imgName={"Wordpress"} imgPath={wordpressLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"PostgreSQL"} imgPath={PostgressLogo} />
                <ImgCard imgName={"Cloudflare"} imgPath={CloudflareLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"Docker"} imgPath={DockerLogo} />
                <ImgCard imgName={"Analytics"} imgPath={GoogleAnalyticsLogo} />
              </div>
              <div className="flex gap-4 md:gap-6 lg:gap-14">
                <ImgCard imgName={"kubernets"} imgPath={kubernetsLogo} />
                <ImgCard imgName={"ML Kit"} imgPath={GoogleMLKitLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechTools;
