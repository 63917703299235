import React from "react";

const ImgCard = ({ imgPath, imgName }) => {
  return (
    <div className="h-[15vh] md:h-[12vh] lg:h-[22vh] w-[15vh] md:w-[12vh] lg:w-[22vh] rounded-3xl border-2 flex flex-col gap-2 justify-center items-center">
      <img src={imgPath} height={"50%"} width={"50%"} alt={imgName}/>
      <p className="text-sm lg:text-xl desktop:text-2xl">{imgName}</p>
    </div>
  );
};

export default ImgCard;
