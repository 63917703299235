import React from "react";

const Card = ({ text1, text2 = null, text3 = null, text4 = null, isColorChange = false }) => {
  return (
    <div>
      <svg className="card" height={1200} width={1200} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path className="border" d="M50 50 L1150 50 L1150 1150 L50 1150 Z" fill="none" stroke="white" strokeWidth={10} />
        {text1 && (
          <text className="text-9xl" x="50%" y={text3 ? "30%" : text2 ? "45%" : "50%"} dominantBaseline="middle" textAnchor="middle" fill={isColorChange ? "#FFD700" : "white"}>
            {text1}
          </text>
        )}
        {text2 && <text className="text-9xl" x="50%" y={text3 ? "45%" : "60%"} dominantBaseline="middle" textAnchor="middle" fill={isColorChange ? "#FFD700" : "white"}>
          {text2}
        </text>}
        {text3 && <text className="text-7xl" x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fill="white">
          {text3}
        </text>}
        {text4 && <text className="text-7xl" x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill="white">
          {text4}
        </text>}
      </svg>
    </div>
  );
};

export default Card;
