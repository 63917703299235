import React, { useEffect, useRef } from "react";
import ManikMobile from "../../assets/ClientLogo/ManikMobile.jpg";
import MurarkaInvestments from "../../assets/ClientLogo/MurarkaInvestments.jpg";
import Samsung from "../../assets/ClientLogo/Samsung.jpg";
import PhoenixElectricals from "../../assets/ClientLogo/PhoenixElectricals.jpg";
import insureBaba from "../../assets/ClientLogo/InsureBaba.jpg";
import Mercados from "../../assets/ClientLogo/Mercados.jpg";

const EsteemedClients = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const isMediumScreenOrAbove = window.matchMedia("(min-width: 800px)").matches;
    if (isMediumScreenOrAbove) {
      const intervalId = setInterval(() => {
        handleScroll();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const handleScroll = () => {
    const firstSlide = carouselRef.current.children[0];
    carouselRef.current.style.transition = "transform 0.5s ease-in-out";
    carouselRef.current.style.transform = "translateX(-33.33%)";
    setTimeout(() => {
      carouselRef.current.appendChild(firstSlide);
      carouselRef.current.style.transition = "none";
      carouselRef.current.style.transform = "translateX(0)";
    }, 500);
  };

  return (
    <div className="flex flex-col gap-10 lg:gap-20 desktop:gap-28 w-full px-5 lg:px-16 lg:p-5 lg:my-10 lg:mb-20">
      <div className="flex flex-col gap-3 items-center justify-center text-4xl lg:text-7xl desktop:text-8xl">
        <h1 className="text-white">Esteemed <span className="text-highlight">Clients</span></h1>
        <h1 className="text-white text-lg lg:text-3xl desktop:text-4xl text-center">BUSINESS EMPOWERED AT TECHB</h1>
      </div>
      <div className="carousel-container overflow-hidden">
        <div className="flex lg:mx-auto gap-5 lg:gap-10 justify-center items-center lg:w-[170vh]" style={{ overflowX: "hidden" }}>
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-20 slide" ref={carouselRef}>
            <div className="flex justify-center items-center gap-5 lg:gap-20">
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={ManikMobile} height={"80%"} width={"80%"} alt="Manik mobile shoppe img" />
              </div>
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={Samsung} height={"80%"} width={"80%"} alt="happy clients img"/>
              </div>
            </div>
            <div className="flex justify-center items-center gap-5 lg:gap-20">
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={MurarkaInvestments} height={"80%"} width={"80%"} alt="Murarka Investments img" />
              </div>
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={PhoenixElectricals} height={"80%"} width={"80%"} alt="Phoenix Electrical img" />
              </div>
            </div>
            <div className="flex justify-center items-center gap-5 lg:gap-20">
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={insureBaba} height={"80%"} width={"80%"} alt="Insure Baba img" />
              </div>
              <div className="border-highlight border-x-2 h-[16vh] sm:h-[20vh] lg:h-[34vh] w-[16vh] sm:w-[20vh] lg:w-[34vh] rounded-3xl flex justify-center items-center">
                <img src={Mercados} height={"80%"} width={"80%"} alt="Mercados logo img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsteemedClients;
