import React from "react";
import Form from "../../Components/Common/Form";
import { MdOutlineMailOutline, MdOutlineHorizontalRule } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { GoDotFill } from "react-icons/go";

const ContactHome = () => {
  return (
    <div className="flex flex-col md:flex-row gap-5 w-full text-white p-5 md:p-0 md:py-5 desktop:py-20 md:pl-5 lg:pl-20">
      <div className="md:w-1/3 hidden md:block">
        <div className="flex flex-col">
          <h1 className="text-3xl lg:text-4xl desktop:text-5xl text-left lg:font-semibold">
            TechB <span className="text-highlight">Softwares</span>
          </h1>
          <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
          <h1 className="text-base lg:text-xl desktop:text-2xl text-left py-4">
            Your Innovative Technology Partner
          </h1>
          <div className="flex flex-col gap-3 text-base lg:text-xl desktop:text-2xl">
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center md:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <MdOutlineMailOutline />
              </p>
              <p className="hover:border-b">info@techbsoftwares.com</p>
            </div>
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center md:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <FaPhoneAlt />
              </p>
              <p>+91 9529556802</p>
            </div>
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center md:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <ImLocation />
              </p>
              <p>Hadapsar, Pune, Maharashtra</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:w-1/3">
        <h1 className="text-3xl lg:text-4xl desktop:text-5xl lg:font-semibold text-left">
          Services
        </h1>
        <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
        <div className="text-base lg:text-xl desktop:text-2xl">
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight"><GoDotFill /></span>
            Web Development
          </p>
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight">
              <GoDotFill />
            </span>
            Mobile Applications
          </p>
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight">
              <GoDotFill />
            </span>
            Software Upgrades
          </p>
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight">
              <GoDotFill />
            </span>
            Graphics Designing
          </p>
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight">
              <GoDotFill />
            </span>
            Digital Marketing
          </p>
          <p className="py-2 lg:py-4 flex items-center justify-start">
            <span className="pr-1 text-highlight">
              <GoDotFill />
            </span>
            SEO & SMM
          </p>
        </div>
      </div>
      <div className="flex flex-col md:w-1/3">
        <h1 className="text-3xl lg:text-4xl desktop:text-5xl lg:font-semibold text-left">Contact Us</h1>
        <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
        <div>
          <Form />
        </div>
      </div>
      <div className="lg:w-1/3 block md:hidden">
        <div className="flex flex-col">
          <h1 className="text-3xl lg:text-4xl desktop:text-5xl text-left lg:font-semibold">
            TechB <span className="text-highlight">Softwares</span>
          </h1>
          <p className="text-highlight text-6xl desktop:text-7xl"><MdOutlineHorizontalRule /></p>
          <h1 className="text-base lg:text-xl desktop:text-2xl text-left py-4">
            Your Innovative Technology Partner
          </h1>
          <div className="flex flex-col gap-3 text-base lg:text-xl desktop:text-2xl">
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center lg:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <MdOutlineMailOutline />
              </p>
              <p className="hover:border-b">info@techbsoftwares.com</p>
            </div>
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center lg:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <FaPhoneAlt />
              </p>
              <p>+91 9529556802</p>
            </div>
            <div className="flex gap-2 lg:gap-5 items-center justify-start sm:justify-center lg:justify-start">
              <p className="border-2 rounded-full p-2 lg:p-3 text-highlight">
                <ImLocation />
              </p>
              <p>Hadapsar, Pune, Maharashtra</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHome;
