import React from "react";
import Card from "../../Components/Common/Card";

const InnovationProcess = () => {
  return (
    <div className="flex flex-col lg:flex-row sm:h-content gap-10 lg:gap-40 w-full justify-center">
      <div className="flex gap-3 lg:flex-col justify-center lg:items-center text-4xl lg:text-6xl desktop:text-7xl">
        <h1 className="text-white">Innovation</h1>
        <h1 className="text-highlight">Process</h1>
      </div>
      <table className="flex flex-col gap-5 justify-center items-center">
        <tr className="flex flex-col sm:flex-row gap-5 lg:gap-10">
          <td>
            <Card text1="Agile" text2="Methodologies" />
          </td>
          <td>
            <Card text1="Problem" text2="Understanding" />
          </td>
          <td>
            <Card text1="Solutions" text2="& Planning" />
          </td>
        </tr>
        <tr className="flex flex-col sm:flex-row gap-5 lg:gap-10">
          <td>
            <Card text1="Technical" text2="Design" />
          </td>
          <td>
            <Card text1="Development" text2="& Testing" />
          </td>
          <td>
            <Card text1="Deployment" text2="& Support" />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default InnovationProcess;
