import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./Pages";
import { Layout } from "./Components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Solutions from "./Pages/Solutions/Solutions";

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
} catch (error) {
  console.log("Ignore: Sentry init issue!");
}

function App () {
  return (
    <Routes>
      <Route path="/" element={< Layout />} >
        <Route path="/" element={< Home />} />
        <Route path="/home" element={< Home />} />
        <Route path="/solutions" element={<Solutions/>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Route>
    </Routes>
  );
}

export default App;
