import React, { useState } from "react";
import axios from "axios";

function Form () {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // You can handle form submission here
  //   // Reset form after submission
  //   submitQuoteRequest(e);
  //   setFormData({
  //     name: '',
  //     email: '',
  //     mobile: '',
  //     message: ''
  //   });
  // };

  function submitQuoteRequest (e) {
    e.preventDefault();
    const name = formData.name;
    const email = formData.email;
    const message = formData.message;
    const mobile = formData.mobile;
    // const mobile = document.getElementById('mobile').value;

    if (name === "" || email === "" || message === "" || mobile === "") {
      document.getElementById("quoteSubmission").innerText = "All fields are required!";
    } else {
      axios.post("https://submit-form.com/cFHpRdM0", {
        name,
        email,
        message,
        mobile
      })
        .then(function (response) {
          setResponseMessage("We have received your message. We will get back to you soon.");
          setFormData({
            name: "",
            email: "",
            mobile: "",
            message: ""
          });
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
          // document.getElementById("quoteSubmission").innerText = "Your message has reached us. We will get back to you soon.";
          // document.getElementById('name').value = "";
          // document.getElementById('email').value = "";
          // document.getElementById('message').value = "";
          // document.getElementById('mobile').value = "";
        })
        .catch(function (response) {
          document.getElementById("quoteSubmission").innerText = "Something went wrong. Please drop us an email.";
          setResponseMessage("Something went wrong. Please drop us an email.");
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
        });
    }
  }

  // var goToSection = function () {
  //     // Function to navigate to specific section in page on nav-link click
  //     var section = this.getAttribute("href");
  //     $('html, body').animate({
  //         scrollTop: $(section).offset().top
  //     }, 1000);

  //     var nav_links = document.getElementsByClassName("nav-link");
  //     for (var i = 0; i < nav_links.length; i++) {
  //         if (section === nav_links[i].getAttribute("href")) {
  //             nav_links[i].classList.add('active');
  //         } else {
  //             nav_links[i].classList.remove('active');
  //         }

  //     }

  // };

  // function onPageLoad() {
  //     var nav_links = document.getElementsByClassName("nav-link");
  //     for (var i = 0; i < nav_links.length; i++) {
  //         if(i===0){
  //             nav_links[i].classList.add('active');
  //         }
  //         nav_links[i].addEventListener('click', goToSection, false);
  //     }
  // }

  return (
    <form onSubmit={submitQuoteRequest} className="text-white w-full text-left">
      <div className="text-sm lg:text-lg desktop:text-xl mr-8 lg:mr-20">
        <label htmlFor="name">Name</label><br/>
        <input
          className="rounded-md my-1 lg:my-2 text-black w-full p-2 lg:p-3 desktop:p-4"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
      </div>
      <div className="text-sm lg:text-lg desktop:text-xl mr-8 lg:mr-20 mt-2 lg:mt-4">
        <label htmlFor="email">Email</label><br/>
        <input
          className="rounded-md my-1 lg:my-2 text-black w-full p-2 lg:p-3 desktop:p-4"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
      </div>
      <div className="text-sm lg:text-lg desktop:text-xl mr-8 lg:mr-20 mt-2 lg:mt-4">
        <label htmlFor="mobile">Mobile No.</label><br/>
        <input
          className="rounded-md my-1 lg:my-2 text-black w-full p-2 lg:p-3 desktop:p-4"
          type="text"
          id="mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          placeholder="Mobile No."
          required
        />
      </div>
      <div className="text-sm lg:text-lg desktop:text-xl mr-8 lg:mr-20 mt-2 lg:mt-4">
        <label htmlFor="message">Message</label><br/>
        <textarea
          className="rounded-md my-1 lg:my-2 text-black w-full p-2 lg:p-3 desktop:p-4"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Write your Message"
          required
        ></textarea>
      </div>
      <button type="submit" className="bg-highlight text-sm lg:text-lg desktop:text-xl mt-2 lg:mt-4 p-2 lg:p-2.5 desktop:p-3 rounded-md lg:rounded-lg px-5 lg:px-10 desktop:px-16 mr-20 text-black font-semibold lg:font-bold hover:bg-yellow-600">Send</button>
      {responseMessage && <p className="mt-2 text-highlight">{responseMessage}</p>}
    </form>
  );
}

export default Form;
