import React from "react";
import { Header, Footer } from "../../Components";
import { Outlet } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="max-h-screen">
        <div className="bg-black h-header sm:h-s_header md:h-m_header desktop:h-header_desk">
          <Header />
        </div>
        <div className="bg-black h-content sm:h-s_content md:h-m_content desktop:h-content_desk overflow-y-scroll w-full overflow-hidden" style={{ "-ms-overflow-style": "none", "scrollbar-width": "none" }}>
          <style>{".bg-black::-webkit-scrollbar { display: none; }"}</style>
          <main className="p-4">
            <Outlet />
          </main>
          <div className="h-footer sm:h-s_footer md:h-m_footer desktop:h-footer_desk">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
