import React from "react";

const SmallHexagon = ({ text, text2, xPercent = "35%", fontWeight, color = "rgba(255, 255, 255, 0.7)", textColor, fontSize = "24", background = "rgba(0, 0, 0, 0.1)", hexagonPath }) => {
  return (
    <>
      <svg className="hexagon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 550">
        <path
          d={"M75 150 L137 258 L262 258 L325 150 L262 42 L137 42 Z"}
          fill={background} stroke={color} strokeWidth={3}
          className={hexagonPath}
        />
        {text && (
          <>
            <text
              x={xPercent}
              y={text2 ? "25%" : "27%"}
              textAnchor="middle"
              dominantBaseline="middle"
              fontWeight={fontWeight}
              fill={textColor}
              fontSize={fontSize}
            >
              {text}
            </text>
          </>
        )}
        {text2 && (
          <>
            <text
              x={xPercent}
              y="30%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill={textColor}
              fontWeight={fontWeight}
              fontSize={fontSize}
            >
              {text2}
            </text>
          </>
        )}
      </svg>
    </>
  );
};

export default SmallHexagon;
