import React from "react";
import WhatWeDoCard from "../../Components/Common/WhatWeDoCard";
import webApplication from "../../assets/Animation/webApplication.json";
import sass from "../../assets/Animation/sass.json";
import enterpriseSoftware from "../../assets/Animation/enterpriseSoftwares.json";
import softwareUpgrade from "../../assets/Animation/softwareUpgrade.json";
import cloudSolution from "../../assets/Animation/cloudSolution.json";
import consultation from "../../assets/Animation/consultation.json";

const ProductOffrings = () => {
  return (
    <div id="productOfferings" className="w-full text-white flex flex-col gap-5 lg:gap-16 desktop:gap-24 px-5 lg:p-10">
      <div className="text-center lg:text-left">
        <h1 className="text-4xl lg:text-7xl desktop:text-8xl">Products & Offerings</h1>
      </div>
      <div className="flex flex-col lg:flex-row mt-5 gap-5 lg:gap-10 desktop:gap-16 justify-center items-center">
        <WhatWeDoCard imgPath={sass} heading="SAAS" description="Our SaaS application offers businesses flexible access to powerful softwares without the need for upfront payment or extensive infrastructure. Customers can leverage our platform on a pay-as-you-go basis, tailored to their usage needs." />
        <WhatWeDoCard imgPath={enterpriseSoftware} classes={"h-[16vh] w-[16vh] my-4"} heading="Enterprise Softwares" description="Customized Enterprise Software platform offers scalable, cloud-based solutions tailored to meet the diverse needs of large organizations, enabling seamless collaboration, data management, security and workflow automation" />
        <WhatWeDoCard imgPath={webApplication} heading="Web 3.0 Applications" description="Embark on a new era of the internet with Web 3.0 solutions powered by state-of-art technology. It prioritizes user privacy, data integrity, seamless  secure, transparent, and decentralized digital experiences" />
      </div>
      <div className="flex flex-col lg:flex-row mt-5 gap-5 lg:gap-10 desktop:gap-16 justify-center items-center">
        <WhatWeDoCard imgPath={cloudSolution} heading="Cloud Solutions" description="Discover the power of advanced cloud solutions, delivering secure and agile infrastructure to drive digital innovation for your business. Utilize cloud technology to optimize efficiency and scalability, ensuring reliability and customization." />
        <WhatWeDoCard imgPath={consultation} heading="Consultations" description="Unlock the full potential of your business with our consultation services. As a trusted technology partner, we deliver strategic guidance and innovative solutions tailored to unique needs like digital transformation, IT optimization, or many more" />
        <WhatWeDoCard imgPath={softwareUpgrade} classes={"h-[16vh] w-[16vh] my-4"} heading="Software Upgrades" description="Our team ensures seamless IT transitions, empowering business with the latest innovations and optimized performance. We modernize systems efficiently and cost-effectively, using evolving industry standards and user demands." />
      </div>
    </div>
  );
};

export default ProductOffrings;
