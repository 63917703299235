import React from "react";
import HexagonalLogo from "../../Components/HexagonalLogo";
import Main from "../../Components/Main";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import SmallHexagonalLogo from "../../Components/SmallHexagonalLogo";

const HomeMain = () => {
  const scrollToSection = () => {
    const section = document.getElementById("productOfferings");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex h-content md: lg:h-content flex-col sm:flex-row items-center gap-6 sm:gap-0 w-full">
      <div className="sm:w-1/2">
        <Main />
      </div>
      <div className="flex justify-center text-center z-50 md:mt-auto lg:mb-10 text-5xl">
        <div className="text-highlight hidden lg:block animate-shake-y hover:cursor-pointer" onClick={scrollToSection}><MdKeyboardDoubleArrowDown /></div>
      </div>
      <div className="hidden lg:block lg:w-1/2">
        <div className="flex justify-center">
          <HexagonalLogo />
        </div>
      </div>
      <div className="block lg:hidden w-full sm:w-1/2 lg:w-0">
        <div className="flex justify-center">
          <SmallHexagonalLogo />
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
