import React from "react";
import { FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="absolute right-3 z-50 bottom-5 md:bottom-20 desktop:bottom-28 md:right-5 lg:right-10 desktop:right-14 border-2 rounded-full p-2 text-white bg-gray-900 hover:bg-black text-lg lg:text-xl desktop:text-2xl"><a href="tel:+919529556802"><FaPhoneAlt /></a></div>
      <footer className="h-footer sm:h-s_footer md:h-m_footer desktop:h-footer_desk bg-black shadow flex items-center justify-center md:justify-end md:px-5 lg:px-10 gap-4 text-sm sm:text-lg lg:text-xl text-white">
        <span className="dark:text-gray-400">
        Copyright © 2024
        </span>
        <a href="https://techbsoftwares.com/" className="hover:underline">
        TechB Softwares
        </a>
      </footer>
    </>
  );
};

export default Footer;
