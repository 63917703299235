import React, { useState } from "react";
import logo from "../assets/TechB branding/TechBLogo.png";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    // <div className="bg-black border-gray-200 flex justify-between">
    <div className="flex flex-wrap justify-between items-center md:fixed w-full shadow-md shadow-gray-600 bg-black">
      <a href="/" className="flex items-center">
        <img
          src={logo}
          alt="Logo"
          className="h-[12vh] md:h-[14vh] desktop:h-[12vh]"
        />
      </a>
      <button
        onClick={toggleMobileMenu}
        type="button"
        className="inline-flex items-center p-2 mr-3 text-sm bg-gray-700 text-gray-500 rounded-lg md:hidden hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-600"
        aria-controls="mobile-menu"
        aria-expanded={isMobileMenuOpen ? "true" : "false"}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : ""}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
        <svg
          className={`w-6 h-6 ${isMobileMenuOpen ? "" : "hidden"}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } w-full md:block md:w-auto z-50 md:z-0 bg-black shadow-md shadow-gray-600 md:shadow-none text-end`}
        id="mobile-menu"
      >
        <ul className="flex flex-col gap-2 md:flex-row md:space-x-4 lg:space-x-8 md:font-medium text-xl lg:text-2xl text-white pr-5 md:pr-5 lg:pr-10">
          <li className="">
            <a
              href="/home"
              className="hover:text-highlight"
              aria-current="page"
            >
              Home
            </a>
          </li>
          <li className="">
            <a
              href="/solutions"
              className="hover:text-highlight"
            >
              Solutions
            </a>
          </li>
          <li className="">
            <a
              href="/about-us"
              className="hover:text-highlight"
            >
              About Us
            </a>
          </li>
          <li className="">
            <a
              href="/contact-us"
              className="hover:text-highlight"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </div>
    // </div>
  );
};

export default Header;
