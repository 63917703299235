import React from "react";
import InnovationProcess from "./InnovationProcess";

const AboutUs = () => {
  return (
    <>
      <div className="p-5 w-full text-white flex flex-col gap-5 md:gap-10 desktop:gap-20">
        <h1 className="text-highlight text-4xl lg:text-6xl desktop:text-7xl text-center">About Us</h1>
        <div className="flex flex-col md:flex-row sm:px-10 gap-5 sm:gap-7 md:gap-14">
          <div className="flex flex-col gap-5 md:gap-7 desktop:gap-14 md:w-1/2 text-base lg:text-2xl desktop:text-3xl text-center">
            <p>TechB Softwares is a visionary technology partner for the businesses. We aim to solve the business problem with state-of-art softwares solutions and consultations.</p>
            <p className="text-highlight text-xl lg:text-3xl desktop:text-4xl">Vision</p>
            <p>Be a technology partner of global repute, to empower business with state-of-art technology and innovative softwares solutions.</p>
            <p className="text-highlight text-xl lg:text-3xl desktop:text-4xl">Mission</p>
            <p>Empowering global businesses through cutting-edge technology and value driven solutions for sustained growth and success.</p>
          </div>
          <div className="flex flex-col gap-5 md:gap-7 desktop:gap-14 md:w-1/2 text-base lg:text-2xl desktop:text-3xl">
            <p>
          At our core, we are dedicated to revolutionizing businesses through
          pioneering technology solutions. Our mission is to be the foremost
          technology partner that businesses turn to for transformative, innovative
          software solutions.
            </p>
            <p>
          We aim to empower organizations globally by delivering top-tier
          technology services that optimizes operations, drives growth, and foster
          sustainable success.
            </p>
            <p>
          We are committed to continual innovation, collaboration, and
          excellence in every aspect, ensuring our clients achieve their goals
          and remain at the forefront of their industries.
            </p>
            <p>
          Delivering quality software and IT consultations, with an aim to be&nbsp;
              <span className="text-highlight"> TECHNOLOGY PARTNER &nbsp;</span>for &nbsp;
              <span className="text-highlight">BUSINESS</span>.
            </p>
          </div>
        </div>
      </div>
      <InnovationProcess />
    </>
  );
};

export default AboutUs;
