import React from "react";

const ProductCard = ({ children, heading, description }) => {
  return (
    <div className="flex flex-wrap text-white border-2 rounded-lg p-3 lg:p-7 w-full lg:w-1/2 md:min-h-[44vh] lg:min-h-[54vh] desktop:min-h-[44vh]">
      <div className="flex flex-col gap-5 desktop:gap-10 p-2">
        <h1 className="text-xl lg:text-4xl desktop:text-5xl text-highlight text-center">{heading}</h1>
        <h1 className="text-justify lg:text-xl desktop:text-2xl">{description}</h1>
        <div className="flex flex-col flex-wrap lg:flex-row items-center lg:justify-between gap-2 md:mt-10 p-1">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
