import React from "react";
import Lottie from "lottie-react";

const WhatWeDoCard = ({ imgPath, heading, description, classes = "h-[20vh] w-[20vh]" }) => {
  return (
    <div className="border-2 rounded-xl flex flex-wrap w-full lg:min-h-[44vh] desktop:min-h-[38vh] px-5 p-3">
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row gap-2 flex-wrap items-center justify-center">
          <div>
            <Lottie
              animationData={imgPath}
              loop
              autoplay
              className={classes}
            />
          </div>
          <div className="text-xl sm:text-2xl lg:text-3xl desktop:text-4xl text-highlight">{heading}</div>
        </div>
        <div className="lg:text-xl desktop:text-2xl p-3 ">{description}</div>
      </div>
    </div>
  );
};

export default WhatWeDoCard;
